import React from 'react';

import NavBar from './components/navbar';



export default function App() {
  return (
    <div className="container">
      <div className="row">
            <NavBar/>
      </div>
      <div className="row">

      </div>

      <div className="row">

        <div className="col-md-2">

          <ul className="list-group">
            <li className="list-group-item">
              Home
            </li>
            <li className="list-group-item">
            Movies
            </li>
            <li className="list-group-item">
              Categories
            </li>
            <li className="list-group-item">
            Manage Catalogue
            </li>
          </ul>

        </div>

        <div className="col-10 text-wrap" >
          <p className="wrap">
          Helloksfmgkfdmkgfsmfmdlkdgmsfmdgkbmdsekdmgbgdjnsgfaedlkmbjfsgnaefdslkbmndjhsganedklfgvbmnjsgaedkflvmbnsjgaedklvmbfnjsgramnedklmvbnjgaemdlkfmvbnjgraefkdlfmvsbnjhgnreakfsdmgbfnjgrwaeksdbfndjlshgaednkgbsfmegaljfsnmskdvmsflkvmdsfklmvklsdmvkldlkfsmklvmskldfmvkslfmvlkfsmvlkfsmvlksfmvklsmlkfm vlskfmvlk
          </p>
        </div>

      </div>

    </div>
  );
}
